import { useContext, useState } from 'react';

import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ThemeModeContext } from '@/contexts/ThemeModeContext';
import { DARK_MODE_THEME } from '@/utils/constants';

const ThemeOption = () => {
  const { t } = useTranslation();
  const { toggleThemeMode } = useContext(ThemeModeContext);
  const themeApp = localStorage.getItem('theme') || DARK_MODE_THEME;

  const [alignmentTheme, setAlignmentTheme] = useState(themeApp);

  const handleChangeToggleTheme = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment !== null) {
      setAlignmentTheme(newAlignment);
      toggleThemeMode(newAlignment);
    }
  };

  return (
    <>
      <Typography variant="body2" sx={{ color: 'text.primary', fontWeight: 'fontWeightMedium' }}>
        {t('home.profile.theme')}
      </Typography>
      <ToggleButtonGroup
        color="primary"
        value={alignmentTheme}
        exclusive
        onChange={handleChangeToggleTheme}
        aria-label="Theme"
        fullWidth
        sx={{ mt: 1 }}
      >
        <ToggleButton value="light">
          <LightModeOutlinedIcon sx={{ fontSize: '2.5rem' }} />
        </ToggleButton>
        <ToggleButton value="dark">
          <DarkModeOutlinedIcon sx={{ fontSize: '2.5rem' }} />
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};

export default ThemeOption;
