import { actionType } from './action';

export default function reportReducer(state: any, action: any) {
  switch (action.type) {
    case actionType.RESTORE_STATE:
      return {
        ...action.payload,
      };
    case actionType.SET_REPORT:
      return {
        ...state,
        report: action?.report,
      };
    default:
      return state;
  }
}
