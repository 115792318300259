import React, { ReactNode } from 'react';

import { Box, useTheme } from '@mui/material';

import BackToTop from '@/components/common/Button/BackToTop';
import useBreakpoints from '@/helpers/useBreakpoints';
import Header from '@/pages/HomePage/Header';
import HeaderMobile from '@/pages/HomePage/HeaderMobile';

interface NewsLayoutProps {
  children: ReactNode;
}

const NewsLayout: React.FC<NewsLayoutProps> = ({ children }) => {
  const { isMobile, isTablet } = useBreakpoints();
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: `${theme.zIndex.drawer + 1}`,
        boxShadow: 'none',
        borderBottom: 'none',
        overflow: 'hidden',
        backgroundColor: 'background.dark',
      }}
    >
      {isMobile || isTablet ? <HeaderMobile /> : <Header />}
      <div>{children}</div>
      <BackToTop />
    </Box>
  );
};

export default NewsLayout;
