import { actionType } from './action';

export default function scanSubdomainReducer(state: any, action: any) {
  switch (action.type) {
    case actionType.RESTORE_STATE:
      return {
        ...action.payload,
      };
    case actionType.SET_SUBDOMAIN:
      return {
        ...state,
        subdomain: action?.subdomain,
      };
    case actionType.SET_STATISTICS:
      return {
        ...state,
        statistics: action?.statistics,
      };
    case actionType.SET_LOADING_STATISTICS:
      return {
        ...state,
        isLoadingStatistics: action?.isLoadingStatistics,
      };
    default:
      return state;
  }
}
