import { actionType } from './action';

export default function commonReducer(state: any, action: any) {
  switch (action.type) {
    case actionType.SET_LOADING:
      return {
        ...state,
        isLoading: action?.isLoading,
      };
    case actionType.SET_LOADING_GIF:
      return {
        ...state,
        isLoadingGif: action?.isLoadingGif,
      };
    case actionType.SET_SCAN_ERROR:
      return {
        ...state,
        isScanError: action?.isScanError,
      };
    case actionType.SHOW_MODAL:
      return {
        ...state,
        showModal: action?.showModal,
      };
    case actionType.SHOW_MODAL_LOGIN_GMAIL:
      return {
        ...state,
        showModalLoginGmail: action?.showModalLoginGmail,
      };
    case actionType.RESTORE_STATE:
      return {
        ...action.payload,
      };
    case actionType.SET_KEY_SEARCH:
      return {
        ...state,
        keySearch: action?.keySearch,
      };
    case actionType.CALL_API_ERROR:
      return {
        ...state,
        apiError: action?.apiError,
      };
    default:
      return state;
  }
}
