import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

import { decrypt } from '@/utils/crypto';

interface WebSocketProps {
  dataWebSocket: any;
  setDataWebSocket: React.Dispatch<React.SetStateAction<any>>; // Kiểu Dispatch của useState
  dataWebSocketOsint: any;
  setDataWebSocketOsint: React.Dispatch<React.SetStateAction<any>>; // Kiểu Dispatch của useState
}

const WebSocketContext = createContext<WebSocketProps | undefined>(undefined);

export const useWebSocketContext = (): WebSocketProps => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};

interface WebSocketProviderProps {
  children: ReactNode;
}

interface WebSocketOsint {
  title: string;
  osint_result: {};
  domain: string;
}

const HOST = process.env.REACT_APP_URL_WS;

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
  const [dataWebSocket, setDataWebSocket] = useState<any[]>([]);
  const [dataWebSocketOsint, setDataWebSocketOsint] = useState<WebSocketOsint[]>([]);
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));

  useEffect(() => {
    if (inforUser?.role !== 'super admin') {
      let socket = new WebSocket(`${HOST}/ws/info_domain/`);

      const connectWebSocket = () => {
        socket.addEventListener('open', (event) => {
          console.log('WebSocket asm connected successfully');

          // Gửi tin nhắn tới server sau khi kết nối thành công
          if (inforUser) {
            const connectMessage = {
              username: inforUser.email,
            };
            socket.send(JSON.stringify(connectMessage));
          }
        });

        // Bắt sự kiện khi nhận được tin nhắn từ server
        socket.addEventListener('message', (event) => {
          const message = JSON.parse(event.data);

          setDataWebSocket((prevData) => {
            const updatedData = [...prevData]; // Tạo bản sao của mảng dữ liệu cũ

            // Tìm index của phần tử có cùng title và domain trong mảng hiện tại
            const messageIndex = updatedData.findIndex(
              (item) => item.title === message.title && item.domain === message.domain,
            );

            if (messageIndex !== -1) {
              // Nếu đã có message với cùng title và domain, thay thế dữ liệu cũ
              updatedData[messageIndex] = message;
            } else {
              // Nếu chưa có, thêm message mới vào danh sách
              updatedData.push(message);
            }

            return updatedData; // Trả về mảng đã cập nhật
          });
        });

        // Bắt sự kiện khi có lỗi
        socket.addEventListener('error', (event) => {
          console.error('WebSocket asm error:', event);
        });

        // Bắt sự kiện khi kết nối đóng
        socket.addEventListener('close', (event) => {
          console.log('WebSocket asm closed:', event);
        });
      };
      connectWebSocket();

      const reconnectInterval = setInterval(() => {
        console.log('Closing and reconnecting WebSocket asm...');
        socket.close();
        socket = new WebSocket(`${HOST}/ws/info_domain/`);
        connectWebSocket();
      }, 600000);

      // Cleanup
      return () => {
        if (socket) {
          socket.close();
        }
        clearInterval(reconnectInterval);
      };
    }
  }, []);

  useEffect(() => {
    if (inforUser?.role !== 'super admin') {
      let socket = new WebSocket(`${HOST}/ws/osint/`);

      const connectWebSocket = () => {
        socket.addEventListener('open', (event) => {
          console.log('WebSocket osint connected successfully');

          // Gửi tin nhắn tới server sau khi kết nối thành công
          if (inforUser) {
            const connectMessage = {
              username: inforUser.email,
            };
            socket.send(JSON.stringify(connectMessage));
          }
        });

        // Bắt sự kiện khi nhận được tin nhắn từ server
        socket.addEventListener('message', (event) => {
          const message = JSON.parse(event.data);

          setDataWebSocketOsint((prevData) => {
            // Kiểm tra xem domain của message đã tồn tại trong prevData chưa
            const index = prevData.findIndex((item) => item.domain === message.domain);

            if (index !== -1) {
              // Nếu tồn tại, thay thế đối tượng cũ bằng message mới
              const updatedData = [...prevData];
              updatedData[index] = message;
              return updatedData;
            }
            // Nếu không tồn tại, thêm message mới vào mảng
            return [...prevData, message];
          });
        });

        // Bắt sự kiện khi có lỗi
        socket.addEventListener('error', (event) => {
          console.error('WebSocket osint error:', event);
        });

        // Bắt sự kiện khi kết nối đóng
        socket.addEventListener('close', (event) => {
          console.log('WebSocket osint closed:', event);
        });
      };
      connectWebSocket();

      const reconnectInterval = setInterval(() => {
        console.log('Closing and reconnecting WebSocket osint...');
        socket.close();
        socket = new WebSocket(`${HOST}/ws/osint/`);
        connectWebSocket();
      }, 600000); // 600000 milliseconds = 10 minutes

      // Cleanup
      return () => {
        if (socket) {
          socket.close();
        }
        clearInterval(reconnectInterval);
      };
    }
  }, []);

  return (
    <WebSocketContext.Provider value={{ dataWebSocket, setDataWebSocket, dataWebSocketOsint, setDataWebSocketOsint }}>
      {children}
    </WebSocketContext.Provider>
  );
};
