import React, { ComponentProps, useEffect, useState } from 'react';

import { Box, useTheme, Typography, Grid, styled } from '@mui/material';
import * as _ from 'lodash';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import AosComponent from '@/components/animation/AosComponent';
import SubPostLabel from '@/components/News/SubPostLabel';
import { NewsConfig } from '@/configs/NewsConfig';
import { NewsTypes } from '@/types/news';

import { data } from './data';

const NewsDetail = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const locale = localStorage.getItem('locale') || 'vi';
  const { checkValidUrl } = NewsConfig();

  const [list, setList] = useState<NewsTypes[]>([]);
  const [detail, setDetail] = useState<NewsTypes>();

  const getIdFromPath = (url: string) => {
    return url.split('-')[url.split('-').length - 1];
  };

  const handleGetDetail = async (path: string) => {
    const id = Number(getIdFromPath(path));
    const detailRes = data.find((item: NewsTypes) => item.id === id);
    setDetail(detailRes);

    const top5News = data.slice(0, 6);
    setList(top5News);
  };

  useEffect(() => {
    const { pathname } = location;
    if (!isNaN(+pathname.split('-')[pathname.split('-').length - 1])) {
      handleGetDetail(location.pathname);
    } else {
      navigate('*');
    }
  }, []);

  const StyledMarkdown = styled(ReactMarkdown)(() => ({
    '& ul': {
      paddingLeft: '12px',
    },
    '& li': {
      marginLeft: '12px',
    },
    '& p:not(:has(*))': {
      marginLeft: '12px',
    },
    '& li::marker': {
      paddingLeft: '12px',
    },
    '& a': {
      // wordBreak: 'break-all',
      color: '#deb887',
      '&:hover': {
        color: '#dda5a5',
      },
    },
    '& strong': {
      color: 'text.secondary',
    },
  }));

  const components = {
    h1: (props: ComponentProps<'h1'>) => {
      const { children } = props;
      return <h1>{children}</h1>;
    },
    p: (props: any) => {
      const { children } = props;
      return <p>{children}</p>;
    },
    li: (props: any) => {
      const { children } = props;
      return <li>{children}</li>;
    },
  };

  return (
    <>
      <Helmet>
        <title>{locale === 'vi' ? detail?.title_vi : detail?.title_en}</title>
      </Helmet>

      {!_.isEmpty(detail) && (
        <Box
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '1200px',
            alignSelf: 'center',
            minHeight: '100vh',
            paddingTop: { md: '120px', xs: '90px' },
            padding: 2,
            mb: 5,
          }}
        >
          <AosComponent dataAos="fade-up" dataAosDuration="1000">
            <Typography
              variant="h3"
              sx={{
                color: 'text.primary',
                fontWeight: 600,
              }}
            >
              {locale === 'vi' ? detail?.title_vi : detail?.title_en}
            </Typography>
          </AosComponent>
          <Grid container sx={{ justifyContent: 'space-between', mt: 4 }}>
            <Grid item xs={12} md={8}>
              <AosComponent dataAos="fade-up" dataAosDuration="1000">
                <Box
                  component="img"
                  src={checkValidUrl(detail.img_url, detail.image_url_minio)}
                  sx={{
                    border: `1px solid ${theme.palette.divider}`,

                    cursor: 'pointer',
                    borderRadius: 2,
                    width: '100%',
                    maxHeight: '500px',
                    alignSelf: 'center',
                  }}
                />
              </AosComponent>
              <Box sx={{ mt: 3 }}>
                <AosComponent dataAos="fade-up" dataAosDuration="1000">
                  <Box
                    sx={{
                      fontSize: { xs: '1.6rem', md: '1.8rem' },
                      mt: 1,
                      color: 'text.secondary',
                      fontWeight: 400,
                      lineHeight: 1.5,
                    }}
                  >
                    <StyledMarkdown components={components} linkTarget="_blank">
                      {locale === 'vi' ? detail?.content_vi : detail?.content_en}
                    </StyledMarkdown>
                  </Box>
                </AosComponent>
              </Box>
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: { xs: 4, md: 0 } }}>
              <SubPostLabel label="news.mostRecent" />

              <Grid container spacing={{ xs: 2, md: 0 }}>
                {list?.map((item: NewsTypes) => (
                  <React.Fragment key={item.id}>
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={12}
                      sx={{
                        display: 'flex',
                        mt: 1,
                      }}
                      key={item.id}
                    >
                      <AosComponent dataAos="fade-left" dataAosDuration="1000">
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={5}
                            onClick={() => {
                              setDetail(item);
                              window.scrollTo({
                                top: 0,
                                behavior: 'smooth',
                              });
                            }}
                            sx={{
                              cursor: 'pointer',
                              borderRadius: 1,
                              overflow: 'hidden',
                              border: `1px solid ${theme.palette.divider}`,
                              ':hover': {
                                '.img': {
                                  transform: 'scale(1.1)',
                                },
                              },
                              mt: { xs: 0, md: 1 },
                              width: '160px',
                            }}
                          >
                            <Box
                              component="img"
                              sx={{ width: '100%', height: { xs: '100px', md: '90px' }, transition: '0.6s' }}
                              className="img"
                              alt=""
                              src={checkValidUrl(item.img_url, item.image_url_minio)}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            md={7}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              mt: { xs: 2, md: 1 },
                              paddingBottom: { xs: 0, md: 1 },
                              minWidth: { md: 0, lg: '150px' },
                            }}
                          >
                            <Link to={`/${item.path}`} style={{ textDecoration: 'none' }}>
                              <Box
                                onClick={() => {
                                  handleGetDetail(item.path);
                                  window.scrollTo({
                                    top: 0,
                                    behavior: 'smooth',
                                  });
                                }}
                                sx={{
                                  marginLeft: { xs: 0, md: 2 },
                                  fontWeight: 600,
                                  fontSize: '1.4rem',
                                  color: 'text.primary',
                                  cursor: 'pointer',
                                  '&:hover': {
                                    color: '#02BB9F',
                                  },
                                  transition: 'color 0.3s ease',
                                  display: '-webkit-box',
                                  WebkitBoxOrient: 'vertical',
                                  overflow: 'hidden',
                                  WebkitLineClamp: { xs: 3, sm: 4 },
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {locale === 'vi' ? item.title_vi : item.title_en}
                              </Box>
                            </Link>
                          </Grid>
                        </Grid>
                      </AosComponent>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};
export default NewsDetail;
