import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme, Box, Toolbar, Collapse, Link } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import LanguageOption from '@/components/Header/Language';
import ThemeOption from '@/components/Header/Theme';
import { DARK_MODE_THEME } from '@/utils/constants';
import { LOGO_NEWS, LOGO_NEWS_DARK } from '@/utils/imgBase64';

const HeaderMobile = () => {
  const theme = useTheme();
  const themeApp = localStorage.getItem('theme') || DARK_MODE_THEME;

  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const CollapseMenu = (
    <Collapse
      in={!open}
      timeout="auto"
      unmountOnExit
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 999,
        backgroundColor: '#fff',
      }}
    >
      <Box
        sx={{
          height: '100vh',
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: 'background.main',
          color: theme.palette.grey[600],
          boxShadow: 'none',
          borderBottom: 'none',
          paddingX: 2,
          paddingY: 1.5,
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', padding: 0, minHeight: 'auto' }}>
          <Link href="/" rel="noopener" underline="none">
            <Box
              component="img"
              src={themeApp === 'dark' ? LOGO_NEWS : LOGO_NEWS_DARK}
              sx={{ width: '100px', display: 'flex', alignItems: 'center' }}
            />
          </Link>

          <IconButton onClick={handleClick}>
            {open ? <MenuIcon sx={{ fontSize: '2rem' }} /> : <CloseIcon sx={{ fontSize: '2rem' }} />}
          </IconButton>
        </Toolbar>
        <Box sx={{ padding: 1, mt: 4 }}>
          <ThemeOption />
          <Box sx={{ mt: 2 }}>
            <LanguageOption />
          </Box>
        </Box>
      </Box>
    </Collapse>
  );

  return (
    <>
      <Toolbar
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          display: 'flex',
          justifyContent: 'space-between',
          zIndex: 11,
          paddingX: 2,
          paddingY: 1.5,
          backgroundColor: 'background.dark',
          boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
        }}
      >
        <Link href="/" rel="noopener" underline="none">
          <Box
            component="img"
            src={themeApp === 'dark' ? LOGO_NEWS : LOGO_NEWS_DARK}
            sx={{ width: 100, display: 'flex', alignItems: 'center' }}
          />
        </Link>
        <IconButton onClick={handleClick}>
          {open ? <MenuIcon sx={{ fontSize: '2rem' }} /> : <CloseIcon sx={{ fontSize: '2rem' }} />}
        </IconButton>
      </Toolbar>
      {CollapseMenu}
    </>
  );
};
export default HeaderMobile;
