import React, { createContext, useContext, useEffect } from 'react';

import reportReducer from './reducer';

interface ReportContextInterface {
  report: any;
  dispatch: React.Dispatch<any>;
}

const ReportContext = createContext<ReportContextInterface>({
  report: null,
  dispatch: () => null,
});

const defaultReportInfo = {
  report: null,
};

interface Props {
  children?: any;
}

export const ReportProvider = ({ children }: Props) => {
  const [reportInfo, dispatch] = React.useReducer(reportReducer, defaultReportInfo);

  const value = React.useMemo(
    () => ({
      ...reportInfo,
      dispatch,
    }),
    [reportInfo],
  );

  useEffect(() => {
    const savedData = localStorage.getItem('report');
    if (savedData) {
      dispatch({ type: 'RESTORE_STATE', payload: JSON.parse(savedData) });
      localStorage.removeItem('report');
    }
  }, []);

  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type === 1) {
        localStorage.setItem('report', JSON.stringify({ ...reportInfo }));
      }
    }
  }, []);
  useEffect(() => {
    localStorage.setItem('report', JSON.stringify({ ...reportInfo }));
  }, [reportInfo]);
  return <ReportContext.Provider value={value}>{children}</ReportContext.Provider>;
};

export const useReportInfo = () => useContext(ReportContext);
