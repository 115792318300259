import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import NewsLayout from '@/layouts/news/NewsLayout';
import News from '@/pages/News/News';
import NewsDetail from '@/pages/News/NewsDetail';
import NotFound from '@/pages/NotFound/NotFound';

const ListRouter = () => {
  // const cyberSecurityNewsRoutes = [
  //   '/news/cyber-security',
  //   '/news/cyber-crime',
  //   '/news/cyber-warfare',
  //   '/news/apt',
  //   '/news/data-breach',
  //   '/news/deep-web',
  //   '/news/digital-id',
  //   '/news/hacking',
  //   '/news/hacktivism',
  //   '/news/phishing',
  // ];

  // const businessNewsRoutes = [
  //   '/news/business',
  //   '/news/finance',
  //   '/news/stock',
  //   '/news/real-estate',
  //   '/news/business-summary',
  // ];

  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<HomePage />} />
        <Route
          path="/score"
          element={
            <AuthGuard>
              <AsmLayout>
                <Score />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/info"
          element={
            <AuthGuard>
              <AsmLayout>
                <Info />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/subdomains"
          element={
            <AuthGuard>
              <AsmLayout>
                <Subdomains />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/vul"
          element={
            <AuthGuard>
              <AsmLayout>
                <Vulnerability />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/spider"
          element={
            <AuthGuard>
              <AsmLayout>
                <Spider />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/cve"
          element={
            <CVELayout>
              <CVEList />
            </CVELayout>
          }
        />
        <Route
          path="/vendors"
          element={
            <CVELayout>
              <CPEPage />
            </CVELayout>
          }
        />
        <Route
          path="/cwe"
          element={
            <CVELayout>
              <CWEPage />
            </CVELayout>
          }
        />
        <Route
          path="/analysis"
          element={
            <CVELayout>
              <Analysis />
            </CVELayout>
          }
        />
        <Route path="/cve/:id" element={<CVEDetails />} />

        {cyberSecurityNewsRoutes.map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <NewsLayout>
                <CyberSecurity />
              </NewsLayout>
            }
          />
        ))}

        {businessNewsRoutes.map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <NewsLayout>
                <Business />
              </NewsLayout>
            }
          />
        ))} */}
        <Route
          path="/"
          element={
            <NewsLayout>
              <News />
            </NewsLayout>
          }
        />
        <Route
          path="/:title"
          element={
            <NewsLayout>
              <NewsDetail />
            </NewsLayout>
          }
        />
        {/* <Route
          path="/report"
          element={
            <AuthGuard>
              <AsmLayout>
                <Report />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/asset"
          element={
            <AuthGuard>
              <AsmLayout>
                <Asset />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/phishing"
          element={
            <AuthGuard>
              <PhishingMailLayout>
                <Phishing />
              </PhishingMailLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/identity"
          element={
            <SuperAdminGuard>
              <IdentityLayout>
                <Identity />
              </IdentityLayout>
            </SuperAdminGuard>
          }
        />
        <Route
          path="/osint/urlscan"
          element={
            <AuthGuard>
              <AsmLayout>
                <Urlscan />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/osint/fofa"
          element={
            <AuthGuard>
              <AsmLayout>
                <Fofa />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/osint/theharvester"
          element={
            <AuthGuard>
              <AsmLayout>
                <Harvester />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/osint/whois"
          element={
            <AuthGuard>
              <AsmLayout>
                <Whois />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/osint/mailhunter"
          element={
            <AuthGuard>
              <AsmLayout>
                <Mailhunter />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/social"
          element={
            <AuthGuard>
              <SocialLayout>
                <Topic />
              </SocialLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/social/overview"
          element={
            <AuthGuard>
              <SocialLayout>
                <Statistics />
              </SocialLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/social/posts"
          element={
            <AuthGuard>
              <SocialLayout>
                <Posts />
              </SocialLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/social/trending"
          element={
            <AuthGuard>
              <SocialLayout>
                <Trending />
              </SocialLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/social/statistics-overview"
          element={
            <AuthGuard>
              <SocialLayout>
                <StatisticsOverview />
              </SocialLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/login"
          element={
            <Logged>
              <Login />
            </Logged>
          }
        />
        <Route
          path="/malware"
          element={
            <AuthGuard>
              <MalwarePage />
            </AuthGuard>
          }
        />
        <Route
          path="/management/asm"
          element={
            <AuthGuard>
              <ManagementLayout>
                <AsmManagement />
              </ManagementLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/management/news/list"
          element={
            <SuperAdminGuard>
              <ManagementLayout>
                <NewsManagementLayout>
                  <News />
                </NewsManagementLayout>
              </ManagementLayout>
            </SuperAdminGuard>
          }
        />
        <Route
          path="/management/news/report"
          element={
            <SuperAdminGuard>
              <ManagementLayout>
                <NewsManagementLayout>
                  <ReportNews />
                </NewsManagementLayout>
              </ManagementLayout>
            </SuperAdminGuard>
          }
        />
        <Route
          path="/management/identity"
          element={
            <SuperAdminGuard>
              <ManagementLayout>
                <IdentityManagement />
              </ManagementLayout>
            </SuperAdminGuard>
          }
        />
        <Route
          path="/management/session-telegram"
          element={
            <SuperAdminGuard>
              <ManagementLayout>
                <SessionTelegramManagement />
              </ManagementLayout>
            </SuperAdminGuard>
          }
        />
        <Route
          path="/management/two-factor"
          element={
            <SuperAdminGuard>
              <ManagementLayout>
                <TwoFAManagement />
              </ManagementLayout>
            </SuperAdminGuard>
          }
        />
        <Route
          path="/management/training"
          element={
            <SuperAdminGuard>
              <ManagementLayout>
                <TrainingManagement />
              </ManagementLayout>
            </SuperAdminGuard>
          }
        />
        <Route
          path="/learning"
          element={
            <LearningLayout>
              <Learning />
            </LearningLayout>
          }
        />
        <Route
          path="/sourcescan"
          element={
            <AuthGuard>
              <SourceCodeScan />
            </AuthGuard>
          }
        />
        <Route
          path="/sourcescan/results"
          element={
            <AuthGuard>
              <CodescanResults />
            </AuthGuard>
          }
        />
        <Route path="/github/callback" element={<GitHubCallback />} />
        <Route path="/gitlab/callback" element={<GitLabCallback />} />

        <Route
          path="/security-assessment"
          element={
            <AuthGuard>
              <SecurityAssessmentLayout>
                <SecurityAssessment />
              </SecurityAssessmentLayout>
            </AuthGuard>
          }
        />
        <Route path="/contact" element={<Contact />} />
        <Route path="/admin" element={<SuperAdmin />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default ListRouter;
