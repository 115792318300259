import { useEffect } from 'react';

import 'aos/dist/aos.css';

interface IAosComponent {
  dataAos: string;
  dataAosDuration?: string;
  children?: React.ReactNode;
}

const AosComponent: React.FC<IAosComponent> = ({ dataAos, dataAosDuration, children }) => {
  // eslint-disable-next-line global-require
  const AOS = require('aos');

  useEffect(() => {
    AOS.init({
      duration: 600,
      once: true,
    });
  }, []);

  return (
    <>
      <div data-aos={dataAos} data-aos-duration={dataAosDuration}>
        {children}
      </div>
    </>
  );
};
export default AosComponent;
