import { useTheme, useMediaQuery } from '@mui/material';

const useBreakpoints = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.only('md'));
  const isLargeDesktop = useMediaQuery(theme.breakpoints.only('lg'));
  const isUltraWide = useMediaQuery(theme.breakpoints.only('xl'));

  return {
    isMobile,
    isTablet,
    isDesktop,
    isLargeDesktop,
    isUltraWide,
  };
};

export default useBreakpoints;
