import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from '../locales/en.json';
import vi from '../locales/vi.json';

// the translations
const resources = {
  en: { translation: en },
  vi: { translation: vi },
};

const locale = localStorage.getItem('locale') || 'vi';

i18n.use(initReactI18next).init({
  resources,
  lng: locale,
  fallbackLng: locale,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
