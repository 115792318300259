import React, { createContext, useContext, useEffect } from 'react';

import { decrypt, encrypt } from '@/utils/crypto';

import socialReducer from './reducer';

interface SocialContextInterface {
  topicSocial: any;
  timeScan: any;
  dispatch: React.Dispatch<any>;
}

const SocialContext = createContext<SocialContextInterface>({
  topicSocial: null,
  timeScan: null,
  dispatch: () => null,
});

const defaultSocialInfo = {
  topicSocial: null,
  timeScan: null,
};

interface Props {
  children?: any;
}

export const SocialProvider = ({ children }: Props) => {
  const [socialInfo, dispatch] = React.useReducer(socialReducer, defaultSocialInfo);

  const value = React.useMemo(
    () => ({
      ...socialInfo,
      dispatch,
    }),
    [socialInfo],
  );

  useEffect(() => {
    const savedData = JSON.parse(decrypt(sessionStorage.getItem('social') as string));
    if (savedData) {
      dispatch({ type: 'RESTORE_STATE', payload: savedData });
      sessionStorage.removeItem('social');
    }
  }, []);

  useEffect(() => {
    const storeSocialInfo = async () => {
      if (window.performance) {
        if (performance.navigation.type === 1) {
          const encryptedData = await encrypt(JSON.stringify({ ...socialInfo }));
          sessionStorage.setItem('social', encryptedData);
        }
      }
    };
    storeSocialInfo();
  }, []);

  useEffect(() => {
    const storeSocialInfo = async () => {
      const encryptedData = await encrypt(JSON.stringify({ ...socialInfo }));
      sessionStorage.setItem('social', encryptedData);
    };
    storeSocialInfo();
  }, [socialInfo]);

  return <SocialContext.Provider value={value}>{children}</SocialContext.Provider>;
};

export const useSocialInfo = () => useContext(SocialContext);
