import { Box, useTheme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import AosComponent from '@/components/animation/AosComponent';

interface SubPostLabelProps {
  label: string;
}

const SubPostLabel: React.FC<SubPostLabelProps> = ({ label }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <AosComponent dataAos="fade-left" dataAosDuration="1000">
      <Box
        sx={{
          display: 'flex',
          borderTop: `2px solid ${theme.palette.text.disabled}`,
          mb: { xs: 3, md: 2 },
        }}
      >
        <Typography
          sx={{
            fontSize: '1.4rem',
            fontWeight: 500,
            textTransform: 'uppercase',
            color: 'common.white',
            width: 'fit-content',
            backgroundColor: 'text.disabled',
            borderRadius: '0px 0px 3px 3px',
            padding: '4px 10px',
            whiteSpace: 'nowrap',
          }}
        >
          {t(label)}
        </Typography>
      </Box>
    </AosComponent>
  );
};
export default SubPostLabel;
