import { actionType } from './action';

export default function scanSitemapReducer(state: any, action: any) {
  switch (action.type) {
    case actionType.RESTORE_STATE:
      return {
        ...action.payload,
      };
    case actionType.SET_SITE_MAP:
      return {
        ...state,
        sitemap: action?.sitemap,
      };
    default:
      return state;
  }
}
