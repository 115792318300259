import { useTranslation } from 'react-i18next';

import { AnalyticType, Category, CatTypes } from '@/types/news';
import { NEWS_DEFAULT } from '@/utils/imgBase64';

export const NewsConfig = () => {
  const { t } = useTranslation();

  const typeNews: CatTypes[] = [
    {
      id: 1,
      label: 'Both',
      param: 'both',
    },
    {
      id: 2,
      label: t('home.label.news.cyberSecurity'),
      param: 'cyber_security',
    },
    {
      id: 3,
      label: t('home.label.news.business'),
      param: 'finance',
    },
  ];

  const typeFilterNews: CatTypes[] = [
    {
      id: 1,
      label: t('home.label.news.cyberSecurity'),
      param: 'cyber_security',
    },
    {
      id: 2,
      label: t('home.label.news.business'),
      param: 'finance',
    },
  ];

  const categoriesBusiness: Category[] = [
    { id: 1, label: t('news.finance'), param: 'finance', path: 'finance' },
    { id: 2, label: t('news.stock'), param: 'stock', path: 'stock' },
    { id: 3, label: t('news.realEstate'), param: 'real_estate', path: 'real-estate' },
    { id: 4, label: t('news.summary'), param: 'news_summary', path: 'business-summary' },
  ];

  const categoriesCyberSecurity: Category[] = [
    { id: 1, label: 'Cyber crime', param: 'Cyber crime', path: 'cyber-crime' },
    { id: 2, label: 'Cyber warfare', param: 'Cyber warfare', path: 'cyber-warfare' },
    { id: 3, label: 'Apt', param: 'Apt', path: 'apt' },
    { id: 4, label: 'Data breach', param: 'Data breach', path: 'data-breach' },
    { id: 5, label: 'Deep web', param: 'Deep web', path: 'deep-web' },
    { id: 6, label: 'Digital id', param: 'Digital id', path: 'digital-id' },
    { id: 7, label: 'Hacking', param: 'Hacking', path: 'hacking' },
    { id: 8, label: 'HackTivism', param: 'HackTivism', path: 'hacktivism' },
    { id: 9, label: 'Phishing', param: 'Phishing', path: 'phishing' },
  ];

  const errorAnalytic: AnalyticType[] = [
    {
      id: 1,
      value: 0,
      label: t('management.news.all'),
    },
    {
      id: 2,
      value: 1,
      label: t('management.news.error'),
    },
    {
      id: 3,
      value: 2,
      label: t('management.news.success'),
    },
  ];

  const checkValidUrl = (url: string | null, urlMinio: string | null) => {
    // Sử dụng biểu thức chính quy để kiểm tra xem URL có bắt đầu bằng "http://" hoặc "https://"
    const regex = /^(http:\/\/|https:\/\/)/;

    if (urlMinio) {
      return urlMinio;
    }
    if (typeof url === 'string' && regex.test(url)) {
      return url;
    }
    return NEWS_DEFAULT;
  };
  return { typeNews, typeFilterNews, categoriesBusiness, categoriesCyberSecurity, errorAnalytic, checkValidUrl };
};
