import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import NotFoundImage from '@/assets/image/404.png';
import useBreakpoints from '@/helpers/useBreakpoints';
import Header from '@/pages/HomePage/Header';
import HeaderMobile from '@/pages/HomePage/HeaderMobile';

const NotFound = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isMobile, isTablet } = useBreakpoints();

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.dark',
          zIndex: `${theme.zIndex.drawer + 1}`,
          color: `${theme.palette.grey[600]}`,
          boxShadow: 'none',
          borderBottom: 'none',
          padding: 2,
        }}
      >
        {isMobile || isTablet ? <HeaderMobile /> : <Header />}

        <Box
          sx={{
            maxWidth: '1130px',
            paddingTop: isMobile ? '30px' : '80px',
            mt: 5,
            alignSelf: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            minHeight: '93vh',
            textAlign: '-webkit-center',
          }}
        >
          <Box
            component="img"
            src={NotFoundImage}
            sx={{
              alignSelf: 'center',
              marginBottom: 2,
              mt: '150px',
            }}
          />
          <Typography sx={{ mt: 4, fontSize: '3rem', color: 'text.primary', fontWeight: 500 }}>
            {t('notFound.title')}
          </Typography>
          <Typography sx={{ mt: 2, color: 'text.secondary' }}>{t('notFound.describe')}</Typography>
        </Box>
      </Box>
    </>
  );
};
export default NotFound;
