export default function Table(theme: any) {
  return {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
        head: {
          color: theme.palette.text.secondary,
          backgroundColor: `${theme.palette.background.bgHeaderTable} !important`,
        },
        body: {
          color: theme.palette.text.primary,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child td': {
            borderBottom: 0,
          },
        },
        head: {
          backgroundColor: `${theme.palette.background.bgHeaderTable} !important`,
        },
      },
    },

    // MuiTableContainer: {
    //   styleOverrides: {
    //     root: {
    //       borderRadius: theme.shape.borderRadius,
    //       borderColor: theme.palette.grey[200],
    //     },
    //   },
    // },
    // MuiTableRow: {
    //   styleOverrides: {
    //     root: {
    //       '&.Mui-selected': {
    //         backgroundColor: theme.palette.action.selected,
    //         '&:hover': {
    //           backgroundColor: theme.palette.action.hover,
    //         },
    //       },
    //     },
    //   },
    // },
    // MUIDataTableBody: {
    //   styleOverrides: {
    //     emptyTitle: {
    //       color: theme.palette.text.secondary,
    //     },
    //   },
    // },
    // MUIDataTableToolbar: {
    //   styleOverrides: {
    //     icon: {
    //       '&:hover': {
    //         color: '#0277bd',
    //       },
    //     },
    //   },
    // },
    // MuiPaper: {
    //   styleOverrides: {
    //     root: {
    //       // '& .MuiDrawer-paper': {
    //       //   paddingTop: '30px',
    //       // },
    //       backgroundColor: 'none',
    //       backgroundImage: 'none',
    //     },
    //   },
    // },
    // MUIDataTableFilter: {
    //   styleOverrides: {
    //     resetLink: {
    //       color: '#0277bd',
    //     },
    //   },
    // },
    // MUIDataTableFooter: {
    //   styleOverrides: {
    //     root: {
    //       textAlign: 'center',
    //     },
    //   },
    // },
    // MuiTableRow: {
    //   styleOverrides: {
    //     head: {
    //       backgroundColor: '#212B36',
    //     },
    //   },
    // },
    // MuiTableCell: {
    //   styleOverrides: {
    //     root: {
    //       // padding: theme.spacing(1),
    //       fontSize: isTablet ? '0.875rem' : '1rem',
    //       borderBottom: `1px solid ${theme.palette.divider}`,
    //     },
    //     head: {
    //       color: 'rgba(255, 255, 255, 0.7)',
    //       // '&:first-of-type': {
    //       //   paddingLeft: theme.spacing(3),
    //       //   borderTopLeftRadius: theme.shape.borderRadius,
    //       //   borderBottomLeftRadius: theme.shape.borderRadius,
    //       //   boxShadow: `inset 8px 0 0 ${theme.palette.background.paper}`,
    //       // },
    //       // '&:last-of-type': {
    //       //   paddingRight: theme.spacing(3),
    //       //   borderTopRightRadius: theme.shape.borderRadius,
    //       //   borderBottomRightRadius: theme.shape.borderRadius,
    //       //   boxShadow: `inset -8px 0 0 ${theme.palette.background.paper}`,
    //       // },
    //     },
    //     // stickyHeader: {
    //     //   backgroundColor: theme.palette.background.paper,
    //     //   backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`,
    //     // },
    //     body: {
    //       color: 'rgba(255, 255, 255, 0.87)',
    //       //   '&:first-of-type': {
    //       //     paddingLeft: theme.spacing(3),
    //       //   },
    //       //   '&:last-of-type': {
    //       //     paddingRight: theme.spacing(3),
    //       //   },
    //     },
    //   },
    // },
    // MuiTablePagination: {
    //   styleOverrides: {
    //     root: {
    //       borderTop: `solid 1px ${theme.palette.divider}`,
    //     },
    //     toolbar: {
    //       height: 64,
    //     },
    //     select: {
    //       '&:focus': {
    //         borderRadius: theme.shape.borderRadius,
    //       },
    //     },
    //     selectIcon: {
    //       width: 20,
    //       height: 20,
    //       marginTop: 2,
    //     },
    //   },
    // },
  };
}
