/* eslint-disable jsx-a11y/media-has-caption */
import * as React from 'react';

import { Box, useTheme } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';

import Logo from '@/assets/image/Logo-white.png';
import { useCommonInfo } from '@/contexts/Common';

import ModalCustom from './ModalCustom';
import { flip, stretchInner, stretchOuter } from '../animation/Keyframes';

interface IProps {
  children: React.ReactNode;
}
const Wrapper = React.memo<IProps>(({ children }) => {
  const theme = useTheme();
  const { isLoadingGif, showModal } = useCommonInfo();
  return (
    <div>
      <div>{children}</div>
      <Backdrop sx={{ color: '#fff', zIndex: 99999 }} open={isLoadingGif}>
        <Box
          sx={{
            position: 'relative',
            width: '200px',
            display: 'flex',
            justifyContent: 'center',

            '&::before': {
              content: '""',
              position: 'absolute',
              bottom: '-136%',
              right: '31%',
              width: '79px',
              height: '79px',
              border: '3px solid #5aa6ff',
              transition: '0.4s ease-in-out',
              animation: `${stretchOuter} 2s linear infinite`,
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: '-85%',
              left: '35%',
              width: '57px',
              height: '57px',
              border: '2px solid  #4eefe4',
              transition: '0.4s ease-in-out',
              animation: `${stretchInner} 2s linear infinite`,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '50px',
              transition: '0.4s ease-in-out',
              animation: `${flip} 3s linear infinite`,
            }}
            component="img"
            src={Logo}
          />
        </Box>
      </Backdrop>
      {showModal && <ModalCustom />}
    </div>
  );
});

export default Wrapper;
