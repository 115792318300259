import React, { createContext, useContext, useEffect } from 'react';

import scanVulReducer from './reducer';

interface ScanVulContextInterface {
  vulnerability: any;
  dispatch: React.Dispatch<any>;
}

const ScanVulContext = createContext<ScanVulContextInterface>({
  vulnerability: null,
  dispatch: () => null,
});

const defaultScanVulInfo = {
  vulnerability: null,
};

interface Props {
  children?: any;
}

export const ScanVulProvider = ({ children }: Props) => {
  const [scanVulInfo, dispatch] = React.useReducer(scanVulReducer, defaultScanVulInfo);

  const value = React.useMemo(
    () => ({
      ...scanVulInfo,
      dispatch,
    }),
    [scanVulInfo],
  );

  useEffect(() => {
    const savedData = localStorage.getItem('scanVul');
    if (savedData) {
      dispatch({ type: 'RESTORE_STATE', payload: JSON.parse(savedData) });
      localStorage.removeItem('scanVul');
    }
  }, []);

  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type === 1) {
        localStorage.setItem('scanVul', JSON.stringify({ ...scanVulInfo }));
      }
    }
  }, []);
  useEffect(() => {
    localStorage.setItem('scanVul', JSON.stringify({ ...scanVulInfo }));
  }, [scanVulInfo]);
  return <ScanVulContext.Provider value={value}>{children}</ScanVulContext.Provider>;
};

export const useScanVulInfo = () => useContext(ScanVulContext);
