import { Box, Grid } from '@mui/material';
import * as _ from 'lodash';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import CarouselNews from '@/components/News/CarouselNews';
import SubItems from '@/components/News/SubItems';
import SubItemsAnimate from '@/components/News/SubItemsAnimate';

import { data } from './data';

const News = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('titleHelmet.news')}</title>
      </Helmet>

      <Box
        sx={{
          maxWidth: '1200px',
          alignSelf: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          minHeight: '100vh',
          position: 'relative',
          padding: { xs: 2 },
        }}
      >
        <Box
          sx={{
            marginTop: { xs: '90px', md: '100px' },
          }}
        >
          <CarouselNews listNews={data.slice(0, 4)} />
          <Grid container spacing={5} sx={{ mt: { sm: 0, md: '10px' } }}>
            <Grid item xs={12} md={8}>
              <SubItems listNews={data} />
              <SubItemsAnimate listNews={data} />
            </Grid>
            <Grid
              item
              xs={0}
              md={4}
              sx={{
                '@media (max-width: 850px)': {
                  display: 'none',
                },
              }}
            />
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default News;
