const actionType = {
  SET_LOADING: 'SET_LOADING',
  SET_LOADING_GIF: 'SET_LOADING_GIF',
  SET_SCAN_ERROR: 'SET_SCAN_ERROR',
  CALL_API_ERROR: 'CALL_API_ERROR',
  SHOW_MODAL: 'SHOW_MODAL',
  SHOW_MODAL_LOGIN_GMAIL: 'SHOW_MODAL_LOGIN_GMAIL',
  RESTORE_STATE: 'RESTORE_STATE',
  SET_KEY_SEARCH: 'SET_KEY_SEARCH',
};

export { actionType };
