import { actionType } from './action';

export default function socialReducer(state: any, action: any) {
  switch (action.type) {
    case actionType.RESTORE_STATE:
      return {
        ...action.payload,
      };
    case actionType.SET_TOPIC_SOCIAL:
      return {
        ...state,
        topicSocial: action?.topicSocial,
      };
    case actionType.SET_TIME_SCAN:
      return {
        ...state,
        timeScan: action?.timeScan,
      };
    default:
      return state;
  }
}
