import React, { createContext, useContext, useEffect } from 'react';

import scanSitemapReducer from './reducer';

interface ScanSitemapContextInterface {
  sitemap: any;
  dispatch: React.Dispatch<any>;
}

const ScanSitemapContext = createContext<ScanSitemapContextInterface>({
  sitemap: null,
  dispatch: () => null,
});

const defaultScanSitemapInfo = {
  sitemap: null,
};

interface Props {
  children?: any;
}

export const ScanSitemapProvider = ({ children }: Props) => {
  const [scanSitemapInfo, dispatch] = React.useReducer(scanSitemapReducer, defaultScanSitemapInfo);

  const value = React.useMemo(
    () => ({
      ...scanSitemapInfo,
      dispatch,
    }),
    [scanSitemapInfo],
  );

  useEffect(() => {
    const savedData = localStorage.getItem('scanSitemap');
    if (savedData) {
      dispatch({ type: 'RESTORE_STATE', payload: JSON.parse(savedData) });
      localStorage.removeItem('scanSitemap');
    }
  }, []);

  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type === 1) {
        localStorage.setItem('scanSitemap', JSON.stringify({ ...scanSitemapInfo }));
      }
    }
  }, []);
  useEffect(() => {
    localStorage.setItem('scanSitemap', JSON.stringify({ ...scanSitemapInfo }));
  }, [scanSitemapInfo]);
  return <ScanSitemapContext.Provider value={value}>{children}</ScanSitemapContext.Provider>;
};

export const useScanSitemapInfo = () => useContext(ScanSitemapContext);
