import { Modal, Box, Typography, useTheme, CardMedia } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Error from '@/assets/image/error.gif';
import { useCommonInfo } from '@/contexts/Common';
import useShowModal from '@/Hooks/common/useShowModal';

const ModalCustom = () => {
  const { showModal } = useCommonInfo();
  const { setShowModal } = useShowModal();
  const handleClose = () => setShowModal({ isShow: false });
  const { t } = useTranslation();

  return (
    <Modal open={showModal.isShow} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { md: 400, xs: 300 },
          bgcolor: 'background.main',
          borderRadius: 2,
          boxShadow: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 2,
        }}
      >
        <CardMedia component="img" sx={{ width: 150 }} image={Error} alt="green iguana" />
        <Typography gutterBottom variant="h5" component="div" sx={{ color: 'text.primary' }}>
          {showModal.title || t('notify.somethingWrong')}
        </Typography>
        <Typography gutterBottom variant="subtitle1" component="div" sx={{ color: 'text.primary' }}>
          {showModal.content || t('notify.networkError')}
        </Typography>
      </Box>
    </Modal>
  );
};

export default ModalCustom;
