import { actionType } from './action';

export default function scanVulReducer(state: any, action: any) {
  switch (action.type) {
    case actionType.RESTORE_STATE:
      return {
        ...action.payload,
      };
    case actionType.SET_VUL:
      return {
        ...state,
        vulnerability: action?.vulnerability,
      };
    default:
      return state;
  }
}
