export default function CssBaseline(theme: any) {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        '*::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
          backgroundColor: theme.palette.scrollbar.background,
        },
        '*::-webkit-scrollbar-track': {
          borderRadius: '10px',
        },
        '*::-webkit-scrollbar-thumb': {
          borderColor: theme.palette.scrollbar.background,
          backgroundColor: theme.palette.scrollbar.thumb,
          borderRadius: '8px',
        },
        '*::-webkit-scrollbar-thumb:hover': {
          backgroundColor: theme.palette.scrollbar.hover,
        },
        '*::-webkit-scrollbar-thumb:active': {
          backgroundColor: theme.palette.scrollbar.active,
        },
        '.MuiPaper-elevation9, .MuiPopover-root .MuiPaper-elevation': {
          boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11) !important',
        },
        a: {
          textDecoration: 'none',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            fontSize: '1.4rem',
          },
          '& .MuiInputBase-input': {
            fontSize: '1.4rem',
          },
          '& .MuiInputBase-input::placeholder': {
            fontSize: '1.4rem',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            fontSize: '1.4rem',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            fontSize: '1.4rem',
          },
          '& .MuiInputBase-input': {
            fontSize: '1.4rem',
          },
          '& .MuiInputBase-input::placeholder': {
            fontSize: '1.4rem',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1.1rem',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          fontSize: '1.4rem',
        },
      },
    },
  };
}
