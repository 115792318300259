import { useState } from 'react';

import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { US_FLAG, VN_FLAG } from '@/utils/imgBase64';

const LanguageOption = () => {
  const { t, i18n } = useTranslation();
  const locale = localStorage.getItem('locale') ?? 'vi';

  const [alignmentLanguage, setAlignmentLanguage] = useState(locale);

  const handleChangeToggleLanguage = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment !== null) {
      setAlignmentLanguage(newAlignment);
      i18n.changeLanguage(newAlignment);
      localStorage.setItem('locale', newAlignment);
    }
  };

  return (
    <>
      <Typography variant="body2" sx={{ color: 'text.primary', fontWeight: 'fontWeightMedium' }}>
        {t('home.profile.language')}
      </Typography>
      <ToggleButtonGroup
        color="primary"
        value={alignmentLanguage}
        exclusive
        onChange={handleChangeToggleLanguage}
        aria-label="Language"
        fullWidth
        sx={{ mt: 1 }}
      >
        <ToggleButton value="vi" sx={{ padding: '8px 0px' }}>
          <Box
            component="img"
            src={VN_FLAG}
            sx={{
              height: '30px',
              cursor: 'pointer',
              opacity: locale === 'vi' ? 1 : 0.5,
              '&:hover': {
                transform: 'scale(1.1)',
                opacity: 1,
              },
            }}
          />
        </ToggleButton>
        <ToggleButton value="en" sx={{ padding: '8px 0px' }}>
          <Box
            component="img"
            src={US_FLAG}
            sx={{
              height: '30px',
              cursor: 'pointer',
              opacity: locale === 'en' ? 1 : 0.5,
              '&:hover': {
                transform: 'scale(1.1)',
                opacity: 1,
              },
            }}
          />
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};

export default LanguageOption;
