// material
import { GlobalStyles as GlobalThemeStyles } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { FONT_PRIMARY } from '@/utils/constants';

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const theme = useTheme();

  return (
    <GlobalThemeStyles
      styles={{
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
          fontSize: '62.5%',
          scrollBehavior: 'smooth',
        },
        body: {
          width: '100%',
          height: '100%',
          fontSize: '1.6rem',
        },
        '#root': {
          width: '100%',
          height: '100%',
          fontFamily: FONT_PRIMARY,
        },
        input: {
          '.MuiInputBase-input': { fontSize: '1.4rem' },
          '&::placeholder': {
            fontSize: '1.2rem',
          },
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
          // '&[type=date]': {
          //   MozAppearance: 'textfield',
          //   '&::-webkit-calendar-picker-indicator': {
          //     backgroundColor: 'rgba(255, 255, 255, 0.6)',
          //     cursor: 'pointer',
          //   },
          // },
        },
        textarea: {
          '&::-webkit-input-placeholder': {
            color: theme.palette.text.disabled,
          },
          '&::-moz-placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
          '&:-ms-input-placeholder': {
            color: theme.palette.text.disabled,
          },
          '&::placeholder': {
            color: theme.palette.text.disabled,
          },
        },

        img: { display: 'block', maxWidth: '100%' },

        // Lazy Load Img
        '.blur-up': {
          WebkitFilter: 'blur(5px)',
          filter: 'blur(5px)',
          transition: 'filter 400ms, -webkit-filter 400ms',
        },
        '.blur-up.lazyloaded ': {
          WebkitFilter: 'blur(0)',
          filter: 'blur(0)',
        },
      }}
    />
  );
}
