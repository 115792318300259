import React, { useEffect, useState } from 'react';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, useTheme } from '@mui/material';

const BackToTop = () => {
  const theme = useTheme();

  const [classAnimation, setClassAnimation] = useState('');

  useEffect(() => {
    // Thêm sự kiện cuộn
    window.addEventListener('scroll', handleScroll);
    // Xóa sự kiện khi component unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    // Hiển thị hoặc ẩn nút dựa trên vị trí cuộn
    if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
      setClassAnimation('animate__animated animate__fadeInUp');
    } else {
      setClassAnimation('animate__animated animate__fadeOutDown');
    }
  };

  const scrollToTop = () => {
    // Cuộn về đầu trang
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Button
      className={classAnimation}
      variant="contained"
      onClick={scrollToTop}
      sx={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        height: '50px',
        borderRadius: 1,
        backgroundColor: 'action.hover',
        boxShadow: 10,
        border: `1px solid ${theme.palette.action.selected}`,
        '&:hover': {
          bgcolor: 'action.selected',
        },
      }}
    >
      <KeyboardArrowUpIcon
        className="animate__animated animate__heartBeat animate__infinite"
        sx={{
          color: 'text.secondary',
          fontSize: '2.8rem',
        }}
      />
    </Button>
  );
};
export default BackToTop;
