import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_URL_CRYPTOJS_KEY;
export const encrypt = async (message: any, key: any = secretKey) => {
  const messageString = typeof message === 'object' ? JSON.stringify(message) : message;
  const ciphertext = CryptoJS.AES.encrypt(messageString, key).toString();
  return ciphertext;
};
export const decrypt = (data: string, key: any = secretKey) => {
  try {
    if (data) {
      const bytes = CryptoJS.AES.decrypt(data, key);
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      if (!originalText) {
        throw new Error('Failed to decrypt data');
      }
      return originalText;
    }
    return 'null';
  } catch (error) {
    return 'null';
  }
};
