export default function Stepper(theme: any) {
  return {
    MuiStepper: {
      styleOverrides: {
        // root: {
        //   '& .MuiStepLabel-label': {
        //     fontSize: '1.4rem',
        //   },
        //   '& .MuiStepIcon-root': {
        //     fontSize: '25px',
        //   },
        //   '& .MuiStepLabel-iconContainer': {
        //     '& .MuiStepIcon-text': {
        //       fontSize: '14px',
        //     },
        //   },
        // },
      },
    },
  };
}
