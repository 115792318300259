import { useEffect, useMemo, useState } from 'react';

import { SnackbarProvider } from 'notistack';
import { useTranslation } from 'react-i18next';

import Wrapper from './components/common/Wrapper';
import './configs/config-i18n';
import {
  AuthProvider,
  ThemeModeContext,
  CommonProvider,
  ScanSubdomainProvider,
  ScanVulProvider,
  ScanSitemapProvider,
  SocialProvider,
} from './contexts';
import { ReportProvider } from './contexts/Report';
import { WebSocketProvider } from './contexts/WebSocketContext';
import ListRouter from './routes/routes';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import { DARK_MODE_THEME } from './utils/constants';
import 'animate.css';

function App() {
  const { i18n } = useTranslation();
  const theme = localStorage.getItem('theme') || DARK_MODE_THEME;
  const [mode, setMode] = useState(theme);
  const locale = localStorage.getItem('locale') as string;

  const themeMode = useMemo(
    () => ({
      toggleThemeMode: (themeSelected: string) => {
        setMode(themeSelected);
        localStorage.setItem('theme', themeSelected);
      },
    }),
    [],
  );

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, []);

  return (
    <CommonProvider>
      <WebSocketProvider>
        <SocialProvider>
          <ScanVulProvider>
            <ScanSubdomainProvider>
              <ScanSitemapProvider>
                <ReportProvider>
                  <AuthProvider>
                    <ThemeModeContext.Provider value={themeMode}>
                      <ThemeConfig mode={mode}>
                        <GlobalStyles />
                        <SnackbarProvider
                          maxSnack={3}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          <Wrapper>
                            <ListRouter />
                          </Wrapper>
                        </SnackbarProvider>
                      </ThemeConfig>
                    </ThemeModeContext.Provider>
                  </AuthProvider>
                </ReportProvider>
              </ScanSitemapProvider>
            </ScanSubdomainProvider>
          </ScanVulProvider>
        </SocialProvider>
      </WebSocketProvider>
    </CommonProvider>
  );
}

export default App;
