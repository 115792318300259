import { keyframes } from '@mui/material';

export const scaleBanner = keyframes`
  0% {
    opacity: 0;
    transform: scale(1);
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
`;

export const rotateCircle = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const gradient = keyframes`
  from {
    background-position: 400% 0;
  }
  to {
    background-position: 200% 0;
  }
`;

export const fadeInDown = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const ripple = keyframes`
  0% {
    transform: scale(.8);
    opacity: 1;
  },
  100% {
    transform: scale(2.4);
    opacity: 0;
  },
`;

export const flip = keyframes`
0% {
  transform:  scale(1.1);
}
50% {
  transform: scale(0.7);
}
100% {
  transform:  scale(1.1);
}
`;

export const stretchOuter = keyframes`
  0% {
    transform: scale(1);
    transform: rotate(360deg);
    border-radius: 10%;
    opacity: 0.8;
  }
  50% {
    transform: scale(0.95);
    border-radius: 50%;
    transform: rotate(180deg);
    opacity: 0.6;
  }
  100% {
    transform: scale(1);
    transform: rotate(0deg);
    border-radius: 10%;
    opacity: 0.8;
  }
`;

export const stretchInner = keyframes`
0% {
  transform: scale(1);
  transform: rotate(0deg);
  border-radius: 20%;
  opacity: 0.8;
}
50% {
  transform: scale(0.95);
  border-radius: 50%;
  transform: rotate(180deg);
  opacity: 0.4;
}
100% {
  transform: scale(1);
  transform: rotate(360deg);
  border-radius: 20%;
  opacity: 0.8;
}
`;

export const gradientIdentity = keyframes`

100% {
  background-position: 200%;
}
`;
