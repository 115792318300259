// ----------------------------------------------------------------------

import { FONT_PRIMARY } from '@/utils/constants';

function pxToRem(value: number) {
  return `${value / 10}rem`;
}

interface IPropsReponsive {
  xs: string;
  sm: string;
  md: string;
  lg: string;
}
function responsiveFontSizes({ xs, sm, md, lg }: IPropsReponsive) {
  return {
    '@media (max-width:599px)': {
      fontSize: xs,
    },
    '@media (min-width:600px)': {
      fontSize: sm,
    },
    '@media (min-width:900px)': {
      fontSize: md,
    },
    '@media (min-width:1200px)': {
      fontSize: lg,
    },
  };
}

const typography = {
  fontFamily: FONT_PRIMARY,
  fontSize: 16,
  htmlFontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    ...responsiveFontSizes({ xs: pxToRem(56), sm: pxToRem(75.406), md: pxToRem(85.69), lg: pxToRem(95.973) }),
  },
  h2: {
    ...responsiveFontSizes({ xs: pxToRem(38), sm: pxToRem(50), md: pxToRem(53.333), lg: pxToRem(60) }),
  },
  h3: {
    ...responsiveFontSizes({ xs: pxToRem(32), sm: pxToRem(41.131), md: pxToRem(44.558), lg: pxToRem(47.986) }),
  },
  h4: {
    ...responsiveFontSizes({ xs: pxToRem(25), sm: pxToRem(29.15), md: pxToRem(32.389), lg: pxToRem(32.389) }),
  },
  h5: {
    ...responsiveFontSizes({ xs: pxToRem(20), sm: pxToRem(20.989), md: pxToRem(23.989), lg: pxToRem(23.989) }),
  },
  h6: {
    ...responsiveFontSizes({ xs: pxToRem(18), sm: pxToRem(20), md: pxToRem(20), lg: pxToRem(20) }),
  },
  subtitle1: {
    fontSize: '1.6rem',
    letterSpacing: '0.015rem',
    fontWeight: 400,
  },
  subtitle2: {
    fontSize: '1.4rem',
    letterSpacing: '0.01rem',
    fontWeight: 500,
  },
  body1: {
    fontSize: '1.6rem',
    letterSpacing: '0.05rem',
    fontWeight: 400,
  },
  body2: {
    fontSize: '1.4rem',
    letterSpacing: '0.025rem',
    fontWeight: 400,
  },
  caption: {
    fontSize: '1.2rem',
    letterSpacing: '0.04rem',
    fontWeight: 400,
  },
  overline: {
    fontSize: '1rem',
    letterSpacing: '0.15rem',
    fontWeight: 500,
  },
  button: {
    fontSize: '1.4rem',
    letterSpacing: '0.125rem',
    fontWeight: 500,
  },
};

export default typography;
