/* eslint-disable import/no-duplicates */
import { useTheme, Box, AppBar, Link as LinkMui, Typography } from '@mui/material';
import { format } from 'date-fns';
import { vi, enUS } from 'date-fns/locale';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import ProfileHeader from '@/components/Header/ProfileHeader';
import { DARK_MODE_THEME } from '@/utils/constants';
import { LOGO_NEWS, LOGO_NEWS_DARK } from '@/utils/imgBase64';

const Header = () => {
  const locale = localStorage.getItem('locale') || 'vi';
  const themeApp = localStorage.getItem('theme') || DARK_MODE_THEME;

  return (
    <>
      <AppBar
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          height: '70px',
          boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'background.dark',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignSelf: 'center' }}>
            <LinkMui href="/" rel="noopener" underline="none">
              <Box
                component="img"
                src={themeApp === 'dark' ? LOGO_NEWS : LOGO_NEWS_DARK}
                sx={{
                  width: '140px',
                  justifyContent: 'center',
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              />
            </LinkMui>
            <Typography variant="body2" sx={{ alignContent: 'center', color: 'text.primary' }}>
              {format(new Date(), 'EEEE, dd/MM/yyyy', { locale: locale === 'vi' ? vi : enUS })}
            </Typography>
          </Box>
          <Box sx={{ alignContent: 'center' }}>
            <ProfileHeader />
          </Box>
        </Box>
      </AppBar>
    </>
  );
};
export default Header;
