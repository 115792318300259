import React, { createContext, useContext, useEffect } from 'react';

import scanSubdomainReducer from './reducer';

interface ScanSubdomainContextInterface {
  subdomain: any;
  statistics: any;
  isLoadingStatistics: boolean;
  dispatch: React.Dispatch<any>;
}

const ScanSubdomainContext = createContext<ScanSubdomainContextInterface>({
  subdomain: null,
  statistics: null,
  isLoadingStatistics: false,
  dispatch: () => null,
});

const defaultScanSubdomainInfo = {
  subdomain: null,
  statistics: null,
  isLoadingStatistics: false,
};

interface Props {
  children?: any;
}

export const ScanSubdomainProvider = ({ children }: Props) => {
  const [scanSubdomainInfo, dispatch] = React.useReducer(scanSubdomainReducer, defaultScanSubdomainInfo);

  const value = React.useMemo(
    () => ({
      ...scanSubdomainInfo,
      dispatch,
    }),
    [scanSubdomainInfo],
  );

  useEffect(() => {
    const savedData = localStorage.getItem('scanSubdomain');
    if (savedData) {
      dispatch({ type: 'RESTORE_STATE', payload: JSON.parse(savedData) });
      localStorage.removeItem('scanSubdomain');
    }
  }, []);

  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type === 1) {
        localStorage.setItem('scanSubdomain', JSON.stringify({ ...scanSubdomainInfo }));
      }
    }
  }, []);
  useEffect(() => {
    localStorage.setItem('scanSubdomain', JSON.stringify({ ...scanSubdomainInfo }));
  }, [scanSubdomainInfo]);
  return <ScanSubdomainContext.Provider value={value}>{children}</ScanSubdomainContext.Provider>;
};

export const useScanSubdomainInfo = () => useContext(ScanSubdomainContext);
